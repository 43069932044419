const lcFirst = (value: string | undefined): string => {
  if (typeof value !== 'string') {
    return '';
  }

  if (value.length === 0) {
    return value;
  }

  return value[0].toLowerCase() + value.slice(1);
};

export { lcFirst };
